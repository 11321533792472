import React from 'react';
import { androidAppDownload, iphoneAppDownload } from '../link-actions';
import {DoubleSection} from "../components/layout/DoubleSection";
import {BottomCTA} from "../components/BottomCTA";
import {SingleSection} from "../components/layout/SingleSection";
import OwlCarousel from "react-owl-carousel";
import {PricingCricket} from "../components/PricingCricket";

const CricketNew = () => {
    return (
        <>
            <section id="home_cricket" className="atf-cover-bg atf-cover-bg-full atf-hero-area atf-align-items-details aft-width-two" style={{ backgroundImage: "url(ipad_batting_frame.png)", backgroundSize: "cover", backgroundPosition: "center center" }}>
                <div className="container">
                    <div className="row atf-content-details align-items-center">
                        <div className="col-12 atf-single-details text-lg-left text-center" style={{ padding: "10px" }}>
                            <h1 style={{fontSize: '42px', color: 'white', paddingTop:'100px'}}>Delayed Video Technology.</h1>
                            <h1 style={{fontSize: '42px', color: 'white', paddingTop:'5px', paddingBottom: '10px'}}>On your phone.</h1>
                            <div>
                                <a href="#none" onClick={() => iphoneAppDownload(`cricket_cta_1`)} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                                <a href="#none" onClick={() => androidAppDownload(`cricket_cta_1`)} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DoubleSection col1={
                <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                    <div style={{ margin: '30px 10px 10px 10px' }}>
                        <h2 style={{ marginBottom: "10px", fontSize: '42px', lineHeight: "1.5" }}>Bowl.<br /><span style={{ color: "yellowgreen" }}>Play Shot.</span><br /><span style={{ color: "#999999" }}>Watch Replay.</span><br />Repeat.</h2>
                    </div>
                    <p style={{ fontSize: "18px", marginTop: "25px" }}>PracticeLoop takes video from your phone.<br />And shows a delayed replay on a second device.<br /><span style={{fontSize: '14px'}}>(TV, Tablet, Laptop, another Phone)</span></p>
                    <p>&nbsp;</p>
                </div>
            }
                           col2={<div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                         <img src="./watch_at_feet.png" alt="Replay at your feet" style={{ width: "100%", maxWidth: "800px" }} ></img>
                     </div>}
                     />
          <SingleSection background={'#F1F8E4'}>
            <div className="text-center">
              <h2 style={{color: 'yellowgreen'}}>Watch an instant replay after each delivery</h2>
            </div>
          </SingleSection>
            <DoubleSection
              col1={
                  <div className="text-center">
                      <p style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '42px', padding: '40px 10px 20px 10px'}}>For players</p>
                      <img src={'/ipad_batting_frame.png'} alt="Replay on iPad" style={{ width: "100%", maxWidth: "400px" }} />
                      <p style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '22px', padding: '10px 10px 20px 10px'}}>Watch replay<br />after <span style={{color: 'yellowgreen'}}>each delivery</span></p>
                      <p style={{color: '#999999', fontSize: '18px'}}>Getting feedback after each shot or ball means you can learn, change, and improve faster than before.</p>
                  </div>
              }
              col2={<div className="text-center">
                <p style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '42px', padding: '40px 10px 20px 10px'}}>For coaches</p>
                <img src={'/ipad_fielding_frame.png'} alt="Replay on iPad" style={{ width: "100%", maxWidth: "400px" }} />
                <p style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '22px', padding: '10px 10px 20px 10px'}}>Players <span style={{color: 'yellowgreen'}}>"get it"</span><br />when they can <span style={{color: 'yellowgreen'}}>see it</span></p>
                <p style={{color: '#999999', fontSize: '18px'}}>Changes sink in faster when the players can see what you're wanting to change.</p>
            </div>
              }
            />
          <p style={{height: '80px'}}>&nbsp;</p>
          <SingleSection background={'yellowgreen'}>
            <div className="container">
              <div className="row clearfix align-self-center">
                <div className="col-lg-2 col-md-1 col-0"></div>
                <img className="col-lg-1 col-md-2 col-3" src="./tripod_special.png" style={{marginTop: '-100px', marginBottom: '-45px'}} alt="Real time view" ></img>

                <div className="col-lg-7 col-md-8 col-9 align-self-center text-center">
                  <p style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '42px', marginBottom: '20px'}}>For Clubs</p>
                  <p style={{color: 'white', fontSize: '22px', marginBottom: '5px'}}>Give your club the edge over the opposition.</p>
                  <p style={{color: 'var(--thm-black)', fontSize: '16px', marginBottom: '30px'}}>Get PracticeLoop for your entire club and we'll include this portable tripod for free.</p>

                  <a style={{backgroundColor:"var(--thm-black)", margin:"10px", borderRadius:"10px", fontSize:"18px", color:"white", padding:"10px 35px"}} href={'/cricket#features-cricket'}>Club Deal</a>
                </div>
                <div className="col-lg-2 col-md-1 col-0"></div>
              </div>
            </div>
          </SingleSection>

          <SingleSection>
            <div className="text-center" style={{color:'var(--thm-black)', margin: '50px 0px'}}>
              <h2>Batting, bowling, and fielding</h2>
            </div>
            <OwlCarousel className='owl-theme' loop={true} margin={10} nav={false} autoplay={true} items={3} dots={true} responsive={{0:{items:1}, 992:{items:3}}}>
              <div className='item text-center'><img src="/ipad_fielding_frame.png" alt="Fielding" /><div style={{width: '100%'}}>Fielding</div></div>
              <div className='item text-center'><img src="/ipad_batting_frame.png" alt="Batting" /><div style={{width: '100%'}}>Batting</div></div>
              <div className='item text-center'><img src="/watch_at_feet.png" alt="Bowling" /><div style={{width: '100%'}}>View replay where you stand</div></div>
              <div className='item text-center'><img src="/cricket_hero.png" alt="Bowling" /><div style={{width: '100%'}}>Bowling</div></div>
            </OwlCarousel>

          </SingleSection>

          <SingleSection background={'#F1F8E4'}>
            <div className="text-center">
              <h2 style={{color: 'yellowgreen'}}>Our goal? To develop skills, faster.</h2>
            </div>
          </SingleSection>

          <section className="atf-about-area atf-section-padding">
            <div className="container">
              <div className="row clearfix align-self-center">

                <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                  <div className="text-center">
                    <h2 style={{color: 'var(--thm-black)', fontSize: '32px', padding: '0px 10px 10px 10px'}}>Just let it run</h2>
                    <img src={'/ipad_batting_frame.png'} alt="Replay on iPad" style={{ width: "100%", maxWidth: "400px" }} />
                    <p style={{color: '#999999', fontSize: '18px'}}>Set it up in a net and let the delayed replay run. Batters and bowlers will love it.</p>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-12 align-self-center">
                  <div className="text-center">
                      <div style={{fontSize: '28px', margin: '40px', backgroundColor: 'yellowgreen', color: 'white', padding: '21px', borderRadius: '100px', width: '80px', height: '80px', display: 'inline-block'}}>OR</div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                  <div className="text-center">
                    <h2 style={{color: 'var(--thm-black)', fontSize: '32px', padding: '0px 10px 10px 10px'}}>Pause and Analyse</h2>
                    <img src={'/ipad_fielding_frame.png'} alt="Replay on iPad" style={{ width: "100%", maxWidth: "400px" }} />
                    <p style={{color: '#999999', fontSize: '18px'}}>Pause the replay and go through each shot, ball, catch, or throw, frame by frame.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

            <SingleSection background={'#F1F8E4'}>
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-6">
                        <div className="text-center" style={{marginBottom: '40px'}}>
                            <h2>Success story</h2>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <div className="our-team wow fadeIn">
                            <div className="pic">
                                <img src="assets/img/team/2.png" alt=""/>
                            </div>
                            <h3 className="title">Kew Cricket Club<br />Melbourne, Australia</h3>
                            <span className="post" style={{ fontSize: "18px"}}>Kew CC use PracticeLoop across 9 mens teams and 15 junior teams, helping players build individual skills to achieve the ultimate team success.</span>
                            <div>&nbsp;</div>
                            <a style={{backgroundColor:"yellowgreen", margin:"10px 10px", borderRadius:"10px", fontSize:"18px", color:"white", padding:"10px 35px"}} href={'/kewcc'}>See how Kew uses PracticeLoop</a>
                        </div>
                    </div>
                </div>
            </SingleSection>

          <DoubleSection
            col1={
              <div className="atf-single-about-img text-center" >
                <div style={{ margin: '30px 10px 10px 10px' }}>
                  <h2 style={{ marginBottom: "10px", fontSize: '42px', lineHeight: "1.5" }}>Proven technology,<br /><span style={{ color: "yellowgreen" }}>now on your phone.</span></h2>
                </div>
                <p style={{ fontSize: "18px", marginTop: "25px" }}>Delayed video technology has been used in indoor nets for years, and it really helps.</p>
                <p style={{ fontSize: "18px", marginTop: "10px" }}>PracticeLoop allows you to take this technology to your club, park, or yard.</p>
                <p>&nbsp;</p>
              </div>

            }

            col2={
              <div className="atf-single-about-img text-center" >
              <img src="./phone_batting_frame.png" alt="Video on your phone" style={{ width: "300px" }} />
                <p>Easy set up</p>
              </div>

            }
          />
            <PricingCricket />
            <SingleSection>
                <div className="text-center">
                    <h2>Take your cricket to the next level</h2>
                </div>
            </SingleSection>
            <BottomCTA key={'cricket'} />

        </ >
    );
}

export default CricketNew;

