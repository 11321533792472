import {androidAppDownload, iphoneAppDownload} from "../link-actions";

export const BottomCTA = (props) => {
  return (<div id="get-started" className="atf-section-padding atf-footer-offset">
      <div className="atf-brand-overlay">
        <div className="container">
          <div className="row justify-content-center clearfix wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
            <div className="col-lg-8 col-md-8 col-11 text-center atf-mailchamp-headding atf-mailchamp-subscribe">
              <h2 className="mb-3 text-center">Try free for 7 days</h2>
              <div>
                <a href="#none" onClick={() => iphoneAppDownload(`${props.key}_bottom_cta`)} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                <a href="#none" onClick={() => androidAppDownload(`${props.key}_bottom_cta`)} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
