export const Pricing = (props) => {
  return (<section id="features" className="atf-pricing-area atf-section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-xl-6">
            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
              <h2 className="">Features and Pricing</h2>
            </div>
          </div>
        </div>


        <div className="row clearfix">
          <div className="col-lg-4 atf-pricing-item">
            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" style={{minHeight: '450px'}}>
              <div className="atf-pricing-heading">
                <div className="atf-pricing-name">
                  <h4>Free</h4>
                </div>
              </div>
              <div className="atf-pricing-body">
                <ul>
                  <li>Delayed replay</li>
                  <li className="offer-list-none"><del>Realtime view</del></li>
                  <li className="offer-list-none"><del>Cast to second device</del></li>
                  <li className="offer-list-none"><del>Pause and analyse</del></li>
                  <li className="offer-list-none"><del>Tags</del></li>
                  <li className="offer-list-none"><del>Email support</del></li>
                  <li>&nbsp;</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-4 atf-pricing-item">
            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
              <div className="atf-pricing-heading">
                <div className="atf-pricing-name">
                  <h4>Pro</h4>
                </div>
              </div>
              <div className="atf-pricing-body">
                <ul>
                  <li>Delayed replay</li>
                  <li>Realtime view</li>
                  <li>Cast to second device</li>
                  <li>Pause and analyse</li>
                  <li>Tags</li>
                  <li>Email support</li>
                </ul>
              </div>
              <h2 style={{marginTop: '20px'}}>
                <div className="row clearfix">
                  <div className="col-6">
                    <div style={{backgroundColor:"var(--thm-black)", margin:"10px", borderRadius:"10px"}}>
                      <p style={{fontSize:"14px", color:"white", padding:"5px"}}>Monthly</p>
                      <p style={{fontSize: '22px', paddingBottom: '10px', color: 'white'}}>$1.99*</p>
                    </div>
                  </div>

                  <div className="col-6">
                    <div style={{backgroundColor:"var(--thm-black)", margin:"10px", borderRadius:"10px"}}>
                      <p style={{fontSize:"14px", color:"white", padding:"5px"}}>Yearly</p>
                      <p style={{fontSize: '22px', paddingBottom: '10px', color: 'white'}}>$12.99*</p>
                    </div>
                  </div>
                </div>
              </h2>
              <p style={{fontSize:"11px"}}>* USD. Prices vary slightly per region.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )}
